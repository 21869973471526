import { Box, Container, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { mapWpImageInformation } from "../../../helpers/clientSide/WordPress/mapWpImageInformation";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import { useWpVitisSettings } from "../../../queries/wpVitisSettings/useWpVitisSettings";
import { SanitizeHTML } from "../../01-atoms/SanitizeHTML/SanitzeHTML";
import WordPressImage from "../../01-atoms/WordPressImage/WordPressImage";
import VTSUnderlinedTitle from "../../02-molecules/VTSUnderlinedTitle/VTSUnderlinedTitle";
import { VTSHomePageHeroProps } from "./VTSHomePageHeroInterfaces";
import { useVTSHomePageHeroStyles } from "./VTSHomePageHeroStyles";

const VTSHomePageHero = (props: VTSHomePageHeroProps): JSX.Element => {
  const heroData = useSinglePage(props.pageId)?.pageFields?.home?.hero;
  const smallTitleRaw = heroData?.smallTitle;
  const titleRaw = heroData?.title;
  const handwrittenSloganChecked = heroData?.handwrittenSlogan;
  const image = mapWpImageInformation(heroData?.backgroundImage);

  const infoData = useWpVitisSettings()?.vitisFields;
  const handWrittenSlogan = infoData?.handwrittenSlogan;

  const classes = useVTSHomePageHeroStyles();

  return (
    <Container
      className={clsx(classes.wrapper, "noMargin")}
      maxWidth={false}
      disableGutters={true}
    >
      <Container className="noMargin">
        <Box className={classes.hero}>
          <Box className={classes.textContainer}>
            <Typography
              variant="body2"
              component="h2"
              className={classes.smallTitle}
            >
              {smallTitleRaw}
            </Typography>

            <VTSUnderlinedTitle
              titleRaw={titleRaw || ""}
              typographyProps={{ variant: "h1", className: classes.title }}
            />

            {handwrittenSloganChecked && (
              <Typography
                variant="h5"
                component="h3"
                className={classes.handWrittenSlogan}
              >
                <SanitizeHTML html={handWrittenSlogan || ""} />
              </Typography>
            )}
          </Box>

          {image && (
            <Box className={classes.imageShapeContainer}>
              <Box className={classes.shapeContainer}>
                <div className={classes.shape} />
              </Box>

              <Box className={classes.imageContainer}>
                <Box className={classes.imageInnerContainer}>
                  <WordPressImage
                    imageInformation={image}
                    imageClass={classes.image}
                    gatsbyImageProps={{ draggable: false }}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </Container>
  );
};

export default VTSHomePageHero;
