import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { VTSCircularLogoTextStyleProps } from "./VTSCircularLogoTextInterfaces";

const logoHeight = 2.681; // 2.681rem = 42.9px (vitis-logo-without-text.svg)
const transformOrigin = (radius: number) => `0 ${logoHeight * radius}rem`;

export const useVTSCircularLogoTextStyles = makeStyles((theme: Theme) => {
  return createStyles({
    wrapper: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    scrollRotation: {
      transformOrigin: ({ radius }) => transformOrigin(radius),
    },
    logo: {
      position: "absolute",
    },
    "@keyframes rotation": {
      "0%": {
        transform: "rotateZ(0)",
      },
      "100%": {
        transform: "rotateZ(360deg)",
      },
    },
    text: {
      transformOrigin: (props: VTSCircularLogoTextStyleProps) =>
        transformOrigin(props.radius),
      top: ({ radius }) => `-${logoHeight * radius}rem`,
      animation: "$rotation 60s linear infinite",
      fontSize: "0.594rem", // 9.5px
      fontFamily: "DM Mono, monospace",
      position: "absolute",
      textTransform: "uppercase",
      color: theme.palette.secondary.main,
    },
    letter: {
      position: "absolute",
      display: "inline-block",
      transformOrigin: ({ radius }) => transformOrigin(radius),
    },
  });
});
