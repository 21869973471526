import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const VTSVerticalTitleStyles = makeStyles((theme: Theme) => {
  return createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(8),
      },
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(12.5),
      },
    },
    title: {
      width: ".4rem",
      "& div": {
        "& p": {
          margin: 0,
          "& em": {
            fontStyle: "normal",
          },
        },
      },
    },
    line: {
      content: "''",
      width: ".4rem",
      height: "4.125rem", // 66px
      borderLeft: `.09rem solid ${theme.palette.secondary.dark}`,
      marginTop: theme.spacing(1),
    },
  });
});
