import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const VTSHomepageServicesSectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  })
);
