import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useVTSHomeTemplate = makeStyles((theme: Theme) =>
  createStyles({
    publicationsWrapper: {
      backgroundColor: theme.palette.primary.main,
    },
    circularLogoText: {
      marginLeft: "80%",
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
      },
    },
    newsTitle: {
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);
