import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useVTSCardWrapperStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardWrapper: {
      alignItems: "center",
    },
    cardMotion: {
      backgroundColor: theme.palette.background.default,
      height: "100%",
      width: "100%",
      padding: 0,
    },
    card: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: 0,
      boxShadow: `0 ${theme.spacing(0.5)} ${theme.spacing(1)} ${
        theme.palette.grey[200]
      }`,
      border: `${theme.spacing(0.1)} solid ${theme.palette.grey[200]}`,
      borderRadius: theme.spacing(1),
    },
    cardMinHeight: {
      minHeight: theme.spacing(8),
    },
    publication: {
      height: "100%",
      backgroundColor: theme.palette.background.default,
    },
    advertisement: {
      backgroundColor: theme.palette.secondary.main,
    },
  })
);
