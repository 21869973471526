export interface BulletPoint {
  icon?: VTSIcon;
  text: string;
}

export interface VTSHomepageServicesInfoProps {
  title: string;
  description?: string;
  bulletPoints?: readonly BulletPoint[];
}

export enum VTSIcon {
  Explore = "explore",
  Person = "person",
  Fingerprint = "fingerprint",
}
