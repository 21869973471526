import { Box, Grid, Typography } from "@material-ui/core";
import ExploreIcon from "@material-ui/icons/Explore";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import PersonIcon from "@material-ui/icons/Person";
import React from "react";
import { SanitizeHTML } from "../../01-atoms/SanitizeHTML/SanitzeHTML";
import VTSUnderlinedTitle from "../VTSUnderlinedTitle/VTSUnderlinedTitle";
import {
  VTSHomepageServicesInfoProps,
  VTSIcon,
} from "./VTSHomepageServicesInfoInterfaces";
import { VTSHomepageServicesInfoStyles } from "./VTSHomepageServicesInfoStyles";

const VTSHomepageServicesInfo = (
  props: VTSHomepageServicesInfoProps
): JSX.Element => {
  const classes = VTSHomepageServicesInfoStyles();

  const icon: { [key in VTSIcon]: JSX.Element } = {
    [VTSIcon.Explore]: <ExploreIcon className={classes.icon} />,
    [VTSIcon.Person]: <PersonIcon className={classes.icon} />,
    [VTSIcon.Fingerprint]: <FingerprintIcon className={classes.icon} />,
  };

  const bulletPoints = () => {
    return props.bulletPoints?.map((bulletPoint) => {
      return (
        <Grid
          key={bulletPoint.text}
          container={true}
          className={classes.bulletPoint}
        >
          <Grid item={true} md={2}>
            <Box className={classes.wrapperIcon}>
              {bulletPoint.icon ? (
                icon[bulletPoint.icon]
              ) : (
                <ExploreIcon className={classes.icon} />
              )}
            </Box>
          </Grid>

          <Grid item={true} className={classes.bulletPointText} md={10}>
            <Typography variant="body1">{bulletPoint.text}</Typography>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Grid item={true} className={classes.wrapper}>
      <VTSUnderlinedTitle
        titleRaw={props.title || ""}
        typographyProps={{ variant: "h2", className: classes.title }}
        component="h3"
      />

      <Typography variant="body1">
        <SanitizeHTML html={props.description || ""} />
      </Typography>

      <Grid container={true} className={classes.bulletPoints}>
        {bulletPoints()}
      </Grid>
    </Grid>
  );
};

export default VTSHomepageServicesInfo;
