import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { SanitizeHTML } from "../SanitizeHTML/SanitzeHTML";
import { VTSVerticalTitleProps } from "./VTSVerticalTitleInterfaces";
import { VTSVerticalTitleStyles } from "./VTSVerticalTitleStyles";

const VTSVerticalTitle = (props: VTSVerticalTitleProps): JSX.Element => {
  const classes = VTSVerticalTitleStyles();

  return (
    <Grid className={classes.wrapper}>
      <Typography variant="h4" component="h2" className={classes.title}>
        <SanitizeHTML html={props.title} />
      </Typography>

      <Box className={classes.line} />
    </Grid>
  );
};

export default VTSVerticalTitle;
