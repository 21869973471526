import { Checkbox, TextField } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Autocomplete } from "@material-ui/lab";
import React from "react";
import { useTranslation } from "react-i18next";
import { VTSAutocompleteProps } from "./VTSAutocompleteInterfaces";
import { useVTSAutocompleteStyles } from "./VTSAutocompleteStyles";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const VTSAutocomplete = (props: VTSAutocompleteProps): JSX.Element => {
  const classes = useVTSAutocompleteStyles();
  const { t } = useTranslation();

  return (
    <Autocomplete
      multiple={true}
      options={props.options}
      className={classes.wrapper}
      onChange={props.onChange}
      value={props.value}
      disableCloseOnSelect={true}
      getOptionLabel={(option) => option}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            className={classes.checkbox}
            checked={selected}
          />

          {option}
        </>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={props.label}
          placeholder={t("publications.addYear")}
        />
      )}
    />
  );
};

export default VTSAutocomplete;
