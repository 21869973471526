import { Container } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { PostTypeInProject } from "../../../../config/wordPressConfig";
import { DefaultPageProps } from "../../../interfaces/WordPress/DefaultPageInterfaces";
import { PageLayout } from "../../../layouts/pageLayout/PageLayout";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import VTSVerticalTitle from "../../01-atoms/VTSVerticalTitle/VTSVerticalTitle";
import VTSCircularLogoText from "../../02-molecules/VTSCircularLogoText/VTSCircularLogoText";
import VTSUnderlinedTitle from "../../02-molecules/VTSUnderlinedTitle/VTSUnderlinedTitle";
import VTSHomePageHero from "../../03-organisms/VTSHomePageHero/VTSHomePageHero";
import VTSHomepagePublicationsSection from "../../03-organisms/VTSHomepagePublicationsSection/VTSHomepagePublicationsSection";
import VTSHomepageServicesSection from "../../03-organisms/VTSHomepageServicesSection/VTSHomepageServicesSection";
import VTSNewsGrid from "../../03-organisms/VTSNewsGrid/VTSNewsGrid";
import { useVTSHomeTemplate } from "./VTSHomeTemplateStyles";

const VTSHomeTemplate = (props: DefaultPageProps): JSX.Element => {
  const classes = useVTSHomeTemplate();
  const servicesVerticalTitle = useSinglePage(props.pageId)?.pageFields?.home
    ?.services?.verticalTitle;
  const publicationsVerticalTitle = useSinglePage(props.pageId)?.pageFields
    ?.home?.publicationsTeaser?.verticalTitle;
  const newsVerticalTitle = useSinglePage(props.pageId)?.pageFields?.home?.news
    ?.verticalTitle;
  const newsTitle = useSinglePage(props.pageId)?.pageFields?.home?.news?.title;
  const { t } = useTranslation();

  return (
    <PageLayout
      currentPostId={props.pageId}
      postType={PostTypeInProject.WpPage}
    >
      <VTSHomePageHero pageId={props.pageId} />

      <Container className="noMargin">
        <VTSVerticalTitle title={servicesVerticalTitle || ""} />

        <Container className="paddingX">
          <VTSHomepageServicesSection pageId={props.pageId} />
        </Container>
      </Container>

      <Container className={clsx("marginBottom", classes.publicationsWrapper)}>
        <VTSVerticalTitle title={publicationsVerticalTitle || ""} />

        <VTSCircularLogoText
          word={t("home.publications")}
          numberOfRepetitions={4}
          radius={2}
          wrapperClass={classes.circularLogoText}
        />

        <Container className="paddingX">
          <VTSHomepagePublicationsSection
            pageId={props.pageId}
            numberOfPublications={3}
          />
        </Container>
      </Container>

      <Container className={clsx("noMargin", "marginBottom")}>
        <VTSVerticalTitle title={newsVerticalTitle || ""} />

        <Container className={clsx("paddingX", "textCenter")}>
          <VTSUnderlinedTitle
            titleRaw={newsTitle || ""}
            typographyProps={{ variant: "h2", className: classes.newsTitle }}
            component="h3"
          />

          <VTSNewsGrid itemCount={3} />
        </Container>
      </Container>
    </PageLayout>
  );
};

export default VTSHomeTemplate;
