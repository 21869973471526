import { ClassNameMap } from "@material-ui/styles";
import { SpringValue } from "react-spring";

export interface VTSCircularLogoTextProps {
  word: string;
  numberOfRepetitions: number;
  radius: number;
  wrapperClass?: string;
  /**
   * defaults to "  "
   */
  glueBetweenWords?: string;
}

export interface VTSCircularLogoTextStyleProps
  extends Pick<VTSCircularLogoTextProps, "radius"> {
  verticalScrollSpeed: number;
}

export interface VTSCircularLogoTextViewModel {
  classes: ClassNameMap;
  individualLetters: JSX.Element[];
  styles: {
    transform: SpringValue<string>;
  };
}
