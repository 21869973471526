import { mapWpImageInformation } from "../../helpers/clientSide/WordPress/mapWpImageInformation";
import { Service } from "../../interfaces/Service";

export const mapWpServicesToServices = (
  wpServices: readonly GatsbyTypes.WpServiceFieldsFragment[]
): Service[] =>
  wpServices.map((wpService) => {
    const service: Service = {
      id: wpService.id,
      slug: wpService.slug || "",
      title: wpService.title || "",
      description: wpService.serviceFields?.description || "",
      linkTarget: wpService.serviceFields?.linkTarget || "",
      detailImage: mapWpImageInformation(wpService.serviceFields?.detailImage),
      cardBackgroundImage: mapWpImageInformation(
        wpService.serviceFields?.cardBackgroundImage
      ),
    };
    return service;
  });
