import { Grid } from "@material-ui/core";
import React, { useMemo } from "react";
import { noNullNoUndefined } from "../../../helpers/general/noNullNoUndefined";
import { Service } from "../../../interfaces/Service";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import { useAllServices } from "../../../queries/wpServices/useAllServices";
import VTSHomepageServicesCards from "../../02-molecules/VTSHomepageServicesCards/VTSHomepageServicesCards";
import VTSHomepageServicesInfo from "../../02-molecules/VTSHomepageServicesInfo/VTSHomepageServicesInfo";
import { BulletPoint } from "../../02-molecules/VTSHomepageServicesInfo/VTSHomepageServicesInfoInterfaces";
import { VTSHomepageServicesProps } from "./VTSHomepageServicesInterfaces";
import { VTSHomepageServicesSectionStyles } from "./VTSHomepageServicesSectionStyles";

const VTSHomepageServicesSection = (
  props: VTSHomepageServicesProps
): JSX.Element => {
  const classes = VTSHomepageServicesSectionStyles();

  const page = useSinglePage(props.pageId);
  const pageServices = page?.pageFields?.home?.services;
  const services: Service[] = useAllServices();

  const bulletPoints = useMemo(() => {
    return (pageServices?.bulletPoints || []).filter(
      noNullNoUndefined
    ) as BulletPoint[];
  }, [pageServices?.bulletPoints]);

  return (
    <Grid container={true} spacing={2} className={classes.wrapper}>
      <Grid item={true} md={8}>
        <VTSHomepageServicesCards services={services} />
      </Grid>

      <Grid item={true} md={4}>
        <VTSHomepageServicesInfo
          title={pageServices?.title || ""}
          description={pageServices?.description || ""}
          bulletPoints={bulletPoints}
        />
      </Grid>
    </Grid>
  );
};

export default VTSHomepageServicesSection;
