import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React from "react";
import { useWpVitisSettings } from "../../../queries/wpVitisSettings/useWpVitisSettings";
import WordPressImage from "../WordPressImage/WordPressImage";
import { VTSCardWithBackgroundProps } from "./VTSCardWithBackgroundInterfaces";
import { VTSCardWithBackgroundStyles } from "./VTSCardWithBackgroundStyles";

const VTSCardWithBackground = (
  props: VTSCardWithBackgroundProps
): JSX.Element => {
  const classes = VTSCardWithBackgroundStyles(props);
  const addressData = useWpVitisSettings()?.vitisFields?.address;

  return (
    <ButtonBase component="a" href={props.linkTarget} className={classes.link}>
      <Card className={classes.card}>
        {props.backgroundImage && (
          <WordPressImage
            imageInformation={props.backgroundImage}
            imageClass={classes.backgroundImage}
          />
        )}

        <Box className={classes.backgroundColorOverlay} />

        <CardContent className={classes.cardContent}>
          <Grid container={true} className={classes.info}>
            <Typography
              variant="h3"
              component="h4"
              color="textSecondary"
              className={classes.title}
            >
              {props.title}
            </Typography>

            <Typography variant="subtitle2" className={classes.subtitle}>
              {props.subtitle}
            </Typography>

            {props.showContactInfo === true ? (
              <Typography
                variant="body1"
                component="h5"
                color="textSecondary"
                className={classes.contactInfo}
              >
                {addressData?.street}

                <br />

                {`${addressData?.postalCode} ${addressData?.city}`}

                {}
              </Typography>
            ) : null}
          </Grid>

          <ArrowForwardIcon className={classes.arrow} fontSize="large" />
        </CardContent>
      </Card>
    </ButtonBase>
  );
};

export default VTSCardWithBackground;
