import { WpPublication } from "../../../queries/wpPaper/useAllPaper";
import { WpPublicationTag } from "../../../queries/wpPaperTag/useAllPublicationTags";
import { VTSAutocompleteProps } from "../../01-atoms/VTSAutocomplete/VTSAutocompleteInterfaces";

export interface VTSPublicationListViewModel {
  allPublicationsSorted: WpPublication[];
  allPublicationTags: WpPublicationTag[];
  yearProps: VTSAutocompleteProps;
  allResultsFilteredOut: boolean;
  resetFilters: VoidFunction;
  hasMoreResults: boolean;
  increaseListLength: VoidFunction;
  noPublicationsAvailable: boolean;
  filteredPublications: WpPublication[];
}

export interface VTSPublicationListProps {
  numberOfPublications?: number;
}
