import { graphql, useStaticQuery } from "gatsby";

export type WpPublicationTag =
  GatsbyTypes.WordPressPaperTagQuery["allWpPaperTag"]["nodes"][0];

export const useAllPublicationTags = (): WpPublicationTag[] => {
  const papers = useStaticQuery<GatsbyTypes.WordPressPaperTagQuery>(graphql`
    query WordPressPaperTag {
      allWpPaperTag {
        nodes {
          ...WpPaperTagFields
        }
      }
    }
  `);

  return papers.allWpPaperTag.nodes as WpPublicationTag[];
};
