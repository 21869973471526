import { graphql, useStaticQuery } from "gatsby";

export type wpServices =
  GatsbyTypes.WordPressServicesQuery["allWpService"]["nodes"];

export const useAllWpServices = (): wpServices => {
  const services = useStaticQuery<GatsbyTypes.WordPressServicesQuery>(graphql`
    query WordPressServices {
      allWpService {
        nodes {
          ...WpServiceFields
        }
      }
    }
  `);

  return services.allWpService.nodes;
};
