import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useVTSNewsCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      border: "none",
      borderRadius: "0",
      boxShadow: `0px 4px 6px ${theme.palette.grey[100]}`,
      padding: theme.spacing(0),
      // height: "100%",
      "& iframe": {
        height: theme.spacing(35),
        width: "100%",
      },
    },
  })
);
