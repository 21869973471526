import { Button, Grid } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import SearchIcon from "@material-ui/icons/Search";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getPublicationTags } from "../../../helpers/clientSide/publicationHelper/publication";
import VTSAutocomplete from "../../01-atoms/VTSAutocomplete/VTSAutocomplete";
import VTSCardAdvertisement from "../../01-atoms/VTSCardAdvertisement/VTSCardAdvertisement";
import VTSCardWrapper from "../../01-atoms/VTSCardWrapper/VTSCardWrapper";
import { childrenType } from "../../01-atoms/VTSCardWrapper/VTSCardWrapperInterfaces";
import VTSPublication from "../../01-atoms/VTSPublication/VTSPublication";
import { VTSPublicationListProps } from "./VTSPublicationListInterfaces";
import { useVTSPublicationListStyles } from "./VTSPublicationListStyles";
import { useVTSPublicationListViewModel } from "./VTSPublicationListViewModel";

const VTSPublicationList = (props: VTSPublicationListProps): JSX.Element => {
  const classes = useVTSPublicationListStyles();
  const { t } = useTranslation();

  const {
    allPublicationsSorted,
    allPublicationTags,
    yearProps,
    allResultsFilteredOut,
    resetFilters,
    hasMoreResults,
    increaseListLength,
    noPublicationsAvailable,
    filteredPublications,
  } = useVTSPublicationListViewModel();

  const thereArePublications = !noPublicationsAvailable;

  // Number of last publications that we want to show
  const lastPublications = useMemo(
    () => allPublicationsSorted.slice(0, props.numberOfPublications),
    [allPublicationsSorted, props.numberOfPublications]
  );

  return (
    <>
      {thereArePublications && (
        <Grid container={true} spacing={2}>
          {props.numberOfPublications ? null : (
            <Grid container={true} item={true} xs={12} md={6}>
              <VTSAutocomplete {...yearProps} />
            </Grid>
          )}

          <Grid
            container={true}
            item={true}
            spacing={1}
            className={classes.wrapperPublicationsList}
          >
            {props.numberOfPublications
              ? lastPublications.map((publication, index) => (
                  <VTSCardWrapper
                    key={publication.id}
                    childrenType={childrenType.Publication}
                    positionInList={index}
                  >
                    <VTSPublication publication={publication} />
                  </VTSCardWrapper>
                ))
              : filteredPublications.map((publication, index) => {
                  return index === 4 ? (
                    <Grid
                      key={publication.id}
                      container={true}
                      item={true}
                      spacing={1}
                    >
                      <VTSCardWrapper
                        publicationTags={true}
                        childrenType={childrenType.Publication}
                        positionInList={index}
                      >
                        <VTSPublication
                          publication={publication}
                          publicationTags={getPublicationTags(
                            publication,
                            allPublicationTags
                          )}
                        />
                      </VTSCardWrapper>

                      <VTSCardWrapper
                        publicationTags={true}
                        childrenType={childrenType.Advertisement}
                        positionInList={index}
                      >
                        <VTSCardAdvertisement />
                      </VTSCardWrapper>
                    </Grid>
                  ) : (
                    <VTSCardWrapper
                      key={publication.id}
                      publicationTags={true}
                      childrenType={childrenType.Publication}
                      positionInList={index}
                    >
                      <VTSPublication
                        publication={publication}
                        publicationTags={getPublicationTags(
                          publication,
                          allPublicationTags
                        )}
                      />
                    </VTSCardWrapper>
                  );
                })}
          </Grid>

          {!props.numberOfPublications && allResultsFilteredOut && (
            <div>
              <SearchIcon />

              <p>{t("publications.noFilteredPublications")}</p>

              <Button onClick={resetFilters}>Alle Filter zurücksetzen</Button>
            </div>
          )}

          <Grid
            container={true}
            item={true}
            className={classes.wrapperButtonLoadMore}
          >
            {!props.numberOfPublications && hasMoreResults && (
              <Button startIcon={<CachedIcon />} onClick={increaseListLength}>
                {t("publications.loadMore")}
              </Button>
            )}
          </Grid>
        </Grid>
      )}

      {noPublicationsAvailable && <div>{t("publications.noPublications")}</div>}
    </>
  );
};

export default VTSPublicationList;
