import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useVTSPublicationStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonBase: {
      width: "100%",
      height: "100%",
    },
    cardContent: {
      display: "flex",
      alignItems: "center",
      height: "100%",
      width: "100%",
      padding: theme.spacing(1.2),
    },
    gridWrapper: {
      height: "100%",
      alignItems: "center",
      justifyContent: "space-between",
    },
    tagsWrapper: {
      flexDirection: "row",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
    },
    publicationTag: {
      backgroundColor: theme.palette.grey[100],
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(0.2),
      paddingBottom: theme.spacing(0.2),
      borderRadius: theme.spacing(0.2),
      color: theme.palette.secondary.main,
    },
    arrowIconWrapper: {
      textAlign: "center",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    arrowIcon: {
      color: theme.palette.primary.contrastText,
    },
  })
);
