import { WpPublication } from "../../../queries/wpPaper/useAllPaper";
import { WpPublicationTag } from "../../../queries/wpPaperTag/useAllPublicationTags";

// get title
export const getPublicationTitle = (
  publication: WpPublication
): string | undefined => publication.title;

// get date
export const getPublicationDate = (
  publication: WpPublication
): Date | undefined => {
  if (!publication.paperFields?.publicatonDate) {
    return undefined;
  }
  const ddmmyyyy = publication.paperFields.publicatonDate.split("/");
  const yyyymmdd = new Date(
    parseInt(ddmmyyyy[2], 10),
    parseInt(ddmmyyyy[1], 10) - 1,
    parseInt(ddmmyyyy[0], 10)
  );
  return yyyymmdd;
};

// get year
export const getPublicationYear = (
  publication: WpPublication
): string | undefined => {
  const yyyymmdd = getPublicationDate(publication);
  if (!yyyymmdd) {
    return undefined;
  }
  const year = yyyymmdd.getFullYear().toString();
  return year;
};

// get tags
export const getPublicationTags = (
  publication: WpPublication,
  publicationTags: WpPublicationTag[]
): string[] | [] => {
  const idPublicationTags: string[] =
    publication.paperTags?.nodes?.reduce((ids: string[], node) => {
      if (node?.id) {
        ids.push(node.id);
      }

      return ids;
    }, []) || [];

  const publicationTagsNeeded = publicationTags.filter((publicationTag) => {
    return idPublicationTags.includes(publicationTag.id);
  });

  const namePublicationTags = publicationTagsNeeded.map((publicationTag) => {
    return publicationTag?.name || "";
  });

  return namePublicationTags;
};
