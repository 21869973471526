import { WordpressImageInformation } from "../../../interfaces/WordPress/WordpressImageInformation";

export enum InfoPosition {
  Center = "center",
  Left = "left",
}

export interface VTSCardWithBackgroundProps {
  title: string;
  subtitle?: string;
  infoPosition: InfoPosition;
  arrowRotated: boolean;
  showContactInfo: boolean;
  height: string;
  backgroundImage?: WordpressImageInformation;
  linkTarget: string;
}
