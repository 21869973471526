import { graphql, useStaticQuery } from "gatsby";

type LinkedInPostFromQuery =
  GatsbyTypes.WpLinkedInPostsQuery["allWpLinkedInPost"]["nodes"];

export const useAllLinkedInPosts = (
  itemCount: number
): LinkedInPostFromQuery => {
  const members = useStaticQuery<GatsbyTypes.WpLinkedInPostsQuery>(graphql`
    query WpLinkedInPosts {
      allWpLinkedInPost(sort: { fields: date, order: DESC }) {
        nodes {
          linkedInEmbedCode {
            linkedinEmbedCode
          }
          title
          id
        }
      }
    }
  `);
  if (itemCount > 0) {
    return members.allWpLinkedInPost.nodes.slice(0, itemCount);
  }
  return members.allWpLinkedInPost.nodes;
};
