import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { hideScrollbar } from "../../../helpers/styles/hideScrollbar";

export const useVTSLightboxDetailStyles = makeStyles((theme: Theme) => {
  const smallTypography = {
    color: theme.palette.grey[300],
    fontSize: theme.spacing(0.75),
    lineHeight: theme.spacing(1),
  };

  const paddingDialogContentElement = {
    paddingTop: theme.spacing(3.5),
    paddingRight: theme.spacing(2.5),
    paddingLeft: theme.spacing(2.5),
  };

  return createStyles({
    dialogContent: {
      display: "flex",
      flexDirection: "row",
      overflow: "hidden",
    },
    dialogContentImage: {
      backgroundColor: theme.palette.grey[100],
      ...paddingDialogContentElement,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    dialogContentDetails: {
      overflowY: "scroll",
      ...hideScrollbar,
      display: "flex",
      ...paddingDialogContentElement,
      paddingBottom: theme.spacing(2.5),
      flexDirection: "row",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
      },
    },
    wrapperImage: {
      position: "relative",
      justifyContent: "center",
      alignItems: "flex-end",
      overflow: "hidden",
      borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
    },
    image: {
      position: "relative",
      width: "100%",
      bottom: 0,
      borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
    },
    wrapperPmidDoi: {
      marginBottom: theme.spacing(1),
      columnGap: theme.spacing(1),
    },
    pmid: {
      ...smallTypography,
    },
    doi: {
      ...smallTypography,
    },
    wrapperPublicationTags: {
      flexDirection: "row",
      justifyContent: "flex-start",
      marginBottom: theme.spacing(1),
      columnGap: theme.spacing(1),
      rowGap: theme.spacing(1),
    },
    publicationTag: {
      backgroundColor: theme.palette.grey[100],
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(0.2),
      paddingBottom: theme.spacing(0.2),
      borderRadius: theme.spacing(0.2),
      color: theme.palette.secondary.main,
    },
    moreDetails: {
      marginTop: theme.spacing(2),
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
    keyFacts: {
      flexDirection: "row",
      justifyContent: "flex-start",
      columnGap: theme.spacing(2),
      flexWrap: "nowrap",
    },
    keyFact: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.only("xs")]: {
        width: "30%",
      },
    },
    factNumber: {
      marginBottom: 0,
    },
    factTitle: {
      ...smallTypography,
    },
    button: {
      padding: theme.spacing(0.75),
      border: `${theme.spacing(0.1)} solid ${theme.palette.grey[100]}`,
      borderRadius: "50%",
      backgroundColor: theme.palette.background.default,
    },
    closeButton: {
      position: "absolute",
      top: 0,
      right: 0,
      margin: theme.spacing(1),
      zIndex: 100,
    },
    wrapperDownloadLinkButtons: {
      flexDirection: "row",
      justifyContent: "flex-end",
      columnGap: theme.spacing(1),
      position: "absolute",
      bottom: 0,
      right: 0,
      padding: theme.spacing(1),
    },
  });
});
