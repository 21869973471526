import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { coverParent } from "../../../helpers/styles/coverParent";
import {
  InfoPosition,
  VTSCardWithBackgroundProps,
} from "./VTSCardWithBackgroundInterfaces";

export const VTSCardWithBackgroundStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      width: "100%",
      textDecoration: "none",
      "&:hover": {
        "& $backgroundColorOverlay": {
          opacity: 0.3,
          transition: "0.3s",
        },
      },
    },
    card: (props: VTSCardWithBackgroundProps) => ({
      position: "relative",
      height: `${props.height}`,
      width: "100%",
      zIndex: 1,
    }),
    backgroundImage: {
      ...coverParent,
    },
    backgroundColorOverlay: {
      ...coverParent,
      backgroundColor: theme.palette.secondary.main,
      opacity: 0.8,
      transition: "0.3s",
    },
    cardContent: {
      height: "100%",
    },
    info: (props: VTSCardWithBackgroundProps) => ({
      position: "relative",
      height: "100%",
      flexDirection: "column",
      alignItems: `${
        props.infoPosition === InfoPosition.Center ? "center" : "flex-start"
      }`,
      textAlign: `${
        props.infoPosition === InfoPosition.Center ? "center" : "left"
      }`,
      justifyContent: `${
        props.infoPosition === InfoPosition.Center ? "center" : "space-between"
      }`,
    }),
    title: (props: VTSCardWithBackgroundProps) => ({
      margin: `${!props.subtitle ? 0 : null}`,
      wordBreak: "break-word",
    }),
    subtitle: {},
    contactInfo: {},
    arrow: (props: VTSCardWithBackgroundProps) => ({
      position: "absolute",
      right: theme.spacing(1),
      bottom: theme.spacing(1),
      transform: `${props.arrowRotated === true ? "rotate(-45deg)" : null}`,
      color: theme.palette.primary.contrastText,
    }),
  })
);
