import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const height = 18.75; // 300px

export const VTSHomepageServicesCardsStyles = makeStyles((theme: Theme) => {
  return createStyles({
    wrapperBigViewport: {
      flexDirection: "row",
      justifyContent: "center",
    },
    wrapperSmallViewport: {
      flexDirection: "column",
    },
    leftColumnWrapper: {
      flexDirection: "column",
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(-height / 2),
      },
    },
    rightColumnWrapper: {
      flexDirection: "column",
    },
  });
});
