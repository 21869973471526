import { graphql, useStaticQuery } from "gatsby";

export type WpPublication =
  GatsbyTypes.WordPressPaperQuery["allWpPaper"]["nodes"][0];

export const useAllPublications = (): WpPublication[] => {
  const papers = useStaticQuery<GatsbyTypes.WordPressPaperQuery>(graphql`
    query WordPressPaper {
      allWpPaper {
        nodes {
          ...WpPaperFields
        }
      }
    }
  `);

  return papers.allWpPaper.nodes as WpPublication[];
};
