import { useMediaQuery, useTheme } from "@material-ui/core";
import { orderBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useVTSPublicationsAutocomplete } from "../../../helpers/clientSide/hooks/useVTSPublicationsAutocomplete";
import { getPublicationDate } from "../../../helpers/clientSide/publicationHelper/publication";
import { PublicationPropertyName } from "../../../interfaces/publication/morePublicationTypes";
import {
  useAllPublications,
  WpPublication,
} from "../../../queries/wpPaper/useAllPaper";
import { useAllPublicationTags } from "../../../queries/wpPaperTag/useAllPublicationTags";
import { VTSPublicationListViewModel } from "./VTSPublicationListInterfaces";

export const useVTSPublicationListViewModel =
  (): VTSPublicationListViewModel => {
    const theme = useTheme();
    const bigViewport = useMediaQuery(theme.breakpoints.up("md"));
    const allPublications = useAllPublications();
    const allPublicationTags = useAllPublicationTags();

    // Sorting allPublications
    const allPublicationsSorted = allPublications.sort((a, b) => {
      const aDate = getPublicationDate(a);
      const bDate = getPublicationDate(b);
      if (aDate !== undefined && bDate !== undefined) {
        // Sorting by year
        if (aDate.getTime() !== bDate.getTime()) {
          return -(aDate.getTime() - bDate.getTime());
        }
        // Sorting by databaseId
        return -(a.databaseId - b.databaseId);
      }
      return 0;
    });

    // Number of (maximum) items that are showing in the list.
    // Each "see more" click will also add this number on top.
    const [currentListSize, setListSize] = useState<number>(3);

    // Setting number of publications depending on the screen size
    useEffect(() => {
      if (bigViewport) {
        return setListSize(6);
      }
      return setListSize(3);
    }, [bigViewport]);

    const increaseListLength = useCallback(
      () => setListSize((old) => old + currentListSize),
      [currentListSize]
    );

    // Setting year as a filter
    const [yearProps, yearFilter, resetYear] = useVTSPublicationsAutocomplete(
      allPublicationsSorted,
      PublicationPropertyName.Year
    );

    // Reseting filters
    const resetFilters = useCallback(() => {
      resetYear();
    }, [resetYear]);

    // All publications filtered by year
    const filteredPublications: WpPublication[] = useMemo(() => {
      const filtered = allPublicationsSorted.filter(yearFilter);

      return orderBy(filtered);
    }, [allPublicationsSorted, yearFilter]);

    // The filtered publications trimmed to the maximum list length
    const filteredPublicationsSliced = useMemo(
      () => filteredPublications.slice(0, currentListSize),
      [currentListSize, filteredPublications]
    );

    return {
      allPublicationsSorted,
      allPublicationTags,
      yearProps,
      allResultsFilteredOut:
        allPublicationsSorted.length > 0 && filteredPublications.length === 0,
      resetFilters,
      hasMoreResults:
        filteredPublicationsSliced.length < filteredPublications.length,
      increaseListLength,
      noPublicationsAvailable: allPublicationsSorted.length === 0,
      filteredPublications: filteredPublicationsSliced,
    };
  };
