import { VTSAutocompleteProps } from "../../components/01-atoms/VTSAutocomplete/VTSAutocompleteInterfaces";
import { AutocompleteFilterOptions } from "../../helpers/clientSide/hooks/useAutocompleteFilter";
import { WpPublication } from "../../queries/wpPaper/useAllPaper";

export enum PublicationPropertyName {
  "Year" = "Year",
}

export type PublicationFilter = (publication: WpPublication) => boolean;

export type PublicationsAutocompleteData = [
  VTSAutocompleteProps,
  PublicationFilter,
  VoidFunction
];

export interface VTSAutocompleteFilter {
  label: string;
  titleGetter: AutocompleteFilterOptions<WpPublication>["getValue"];
}
