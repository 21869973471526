import { Box } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useSpring } from "react-spring";
import { useScrollData } from "scroll-data-hook";
import {
  VTSCircularLogoTextProps,
  VTSCircularLogoTextViewModel,
} from "./VTSCircularLogoTextInterfaces";
import { useVTSCircularLogoTextStyles } from "./VTSCircularLogoTextStyles";

const defaultGlueBetweenWords = "  ";

const scrollBasedSpinning = {
  /**
   * A higher the factor will cause a more subtle effect
   */
  movementFactor: 2000,

  /**
   * Spring configuration for the scroll-based spinning.
   * https://react-spring-visualizer.com/ nicely explains the different settings.
   */
  springConfig: {
    mass: 1.8,
    tension: 255,
    friction: 220,
  },
};

export const useVTSCircularLogoTextViewModel = (
  props: VTSCircularLogoTextProps
): VTSCircularLogoTextViewModel => {
  const { speed } = useScrollData({});

  const classes = useVTSCircularLogoTextStyles({
    radius: props.radius,
    verticalScrollSpeed: speed.y,
  });
  const [currentRotation, setCurrentRotation] = useState<number>(0);

  const styles = useSpring({
    config: scrollBasedSpinning.springConfig,
    to: {
      transform: `rotateZ(${currentRotation}deg)`,
    },
  });

  useEffect(() => {
    setCurrentRotation((rotation) => {
      const movement = speed.y / scrollBasedSpinning.movementFactor;
      const valueToAdd = movement > 0.2 ? movement : 0;
      return rotation + valueToAdd;
    });
  }, [speed.y]);

  // The full string, containing all repetitions and the glue in between words
  const fullString: string = useMemo(
    () =>
      Array.from({ length: props.numberOfRepetitions })
        .map(() => props.word)
        .join(props.glueBetweenWords || defaultGlueBetweenWords),
    [props.glueBetweenWords, props.numberOfRepetitions, props.word]
  );

  const individualLetters: JSX.Element[] = useMemo(
    () =>
      fullString.split("").map((letter, index) => {
        const splitedTextLength =
          (props.word.length + 2) * props.numberOfRepetitions;

        return (
          <Box
            // It's totally fine in this case, sine we're concating with the word
            // eslint-disable-next-line react/no-array-index-key
            key={props.word + letter + index}
            className={classes.letter}
            // (360deg/number_of_characters)n
            // n=1,2,3,...,number_of_characters
            style={{
              transform: `rotate(${(360 / splitedTextLength) * index}deg)`,
            }}
          >
            {letter}
          </Box>
        );
      }),
    [classes.letter, fullString, props.numberOfRepetitions, props.word]
  );
  return {
    individualLetters,
    classes,
    styles,
  };
};
