import { Service } from "../../interfaces/Service";
import { mapWpServicesToServices } from "./mapWpServicesToServices";
import { useAllWpServices } from "./useAllWpServices";

export const useAllServices = (): Service[] => {
  const allWpServices = useAllWpServices();
  const mappedServices = mapWpServicesToServices(allWpServices);

  return mappedServices;
};
