import { Card } from "@material-ui/core";
import parse from "html-react-parser";
import React from "react";
import { VTSNewsCardProps } from "./VTSNewsCardInterfaces";
import { useVTSNewsCardStyles } from "./VTSNewsCardStyles";

const VTSNewsCard = (props: VTSNewsCardProps): JSX.Element => {
  const classes = useVTSNewsCardStyles();
  return (
    <Card className={classes.wrapper} variant="outlined">
      {parse(props.linkedInEmbedCode)}
    </Card>
  );
};

export default VTSNewsCard;
