import { Grid } from "@material-ui/core";
import React from "react";
import { useAllLinkedInPosts } from "../../../queries/wpLinkedInPosts/wpLinkedInAllPosts";
import VTSNewsCard from "../../02-molecules/VTSNewsCard/VTSNewsCard";
import { VTSNewsGridInterface } from "./VTSNewsGridInterface";

const VTSNewsGrid = (props: VTSNewsGridInterface): JSX.Element => {
  const { itemCount } = props;
  const linkedInPosts = useAllLinkedInPosts(itemCount);

  return (
    <Grid container={true} spacing={4} className={props.wrapperClass}>
      {Object.values(linkedInPosts).map((linkedInPost) => {
        const embedCode = linkedInPost.linkedInEmbedCode?.linkedinEmbedCode;
        return (
          <Grid key={linkedInPost.id} item={true} xs={12} sm={6} md={4}>
            <VTSNewsCard
              title={linkedInPost.title ?? ""}
              linkedInEmbedCode={embedCode ?? ""}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default VTSNewsGrid;
