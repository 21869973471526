import { useCallback, useMemo, useState } from "react";
import { AutocompletePropsDefault } from "../../../interfaces/autocomplete";
import { filterUnique } from "../../general/filterUnique";
import { noNullNoUndefined } from "../../general/noNullNoUndefined";

export interface AutocompleteFilterOptions<TypeThatsFiltered> {
  data: TypeThatsFiltered[];
  getValue: (input: TypeThatsFiltered) => string | undefined | string[];
}

export interface AutocompleteFilter<TypeThatsFiltered>
  extends Pick<AutocompletePropsDefault, "onChange" | "options"> {
  filterCallback: (value: TypeThatsFiltered) => boolean;
  reset: VoidFunction;
  value: string[];
}

export const useAutocompleteFilter = <TypeThatsFiltered>(
  filterOptions: AutocompleteFilterOptions<TypeThatsFiltered>
): AutocompleteFilter<TypeThatsFiltered> => {
  /**
   * Storing which values are selected in the autoselect.
   */
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const reset = () => setSelectedValues([]);

  const options = useMemo(
    () =>
      filterOptions.data
        .map(filterOptions.getValue)
        .flat(1)
        .filter(filterUnique)
        .filter(noNullNoUndefined),
    [filterOptions.data, filterOptions.getValue]
  );

  const filterCallback = useCallback(
    (input: TypeThatsFiltered) => {
      // No value selected
      if (selectedValues.length === 0) {
        return true;
      }

      const comparisonValue = filterOptions.getValue(input);

      // The entity does not have a label
      if (!comparisonValue || comparisonValue.length === 0) {
        return true;
      }

      const listOfComparisonValues = Array.isArray(comparisonValue)
        ? comparisonValue
        : [comparisonValue];

      // The entity is contained in the set of selected labels
      return listOfComparisonValues.some((value) =>
        selectedValues.includes(value)
      );
    },
    [filterOptions, selectedValues]
  );

  const onChange: AutocompleteFilter<TypeThatsFiltered>["onChange"] = (
    e,
    values
  ) => setSelectedValues(values);

  return {
    options,
    value: selectedValues,
    onChange,
    reset,
    filterCallback,
  };
};
