import {
  ButtonBase,
  Card,
  CardContent,
  Dialog,
  Grid,
  Typography,
} from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import { useLocation } from "@reach/router";
import React, { useEffect } from "react";
import { useBoolean } from "use-boolean";
import { getPublicationTitle } from "../../../helpers/clientSide/publicationHelper/publication";
import { removeHash } from "../../../helpers/general/removeHash";
import VTSLightboxDetail from "../VTSLightboxDetail/VTSLightboxDetail";
import { VTSPublicationProps } from "./VTSPublicationInterfaces";
import { useVTSPublicationStyles } from "./VTSPublicationStyles";

const VTSPublication = (props: VTSPublicationProps): JSX.Element => {
  const classes = useVTSPublicationStyles();
  const location = useLocation();

  const [isLightboxOpen, setLightboxOpen, setLightboxClose] = useBoolean(false);

  // Handle open/close lightbox
  const handleOpenLightbox = () => {
    setLightboxOpen();

    globalThis.location.hash = props.publication.slug || "";
  };

  const handleCloseLightBox = () => {
    setLightboxClose();
    removeHash();
  };

  // Handle browser back button
  useEffect(() => {
    globalThis.addEventListener("popstate", setLightboxClose);
    return () => {
      globalThis.removeEventListener("popstate", setLightboxClose);
    };
  });

  // Redirecting when the url is used
  const publicationHash = `#${props.publication.slug}`;

  useEffect(() => {
    if (location.hash === publicationHash) {
      setLightboxOpen();
    }
  }, [setLightboxOpen, location.hash, publicationHash]);

  return (
    <>
      <ButtonBase onClick={handleOpenLightbox} className={classes.buttonBase}>
        <CardContent className={classes.cardContent}>
          <Grid container={true} spacing={1} className={classes.gridWrapper}>
            <Grid
              item={true}
              xs={12}
              md={
                props.publicationTags && props.publicationTags?.length !== 0
                  ? 5
                  : 11
              }
            >
              <Typography variant="body1" component="h4">
                <strong>{getPublicationTitle(props.publication)}</strong>
              </Typography>
            </Grid>

            {props.publicationTags && props.publicationTags.length !== 0 && (
              <Grid
                item={true}
                container={true}
                spacing={1}
                xs={11}
                md={6}
                className={classes.tagsWrapper}
              >
                {props.publicationTags.map((publicationTag) => {
                  return (
                    <Grid key={publicationTag} item={true}>
                      <Card className={classes.publicationTag}>
                        <CardContent>{publicationTag}</CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            )}

            <Grid
              item={true}
              container={true}
              xs={12}
              md={1}
              className={classes.arrowIconWrapper}
            >
              <ArrowForwardIos className={classes.arrowIcon} />
            </Grid>
          </Grid>
        </CardContent>
      </ButtonBase>

      <Dialog
        onClose={handleCloseLightBox}
        open={isLightboxOpen}
        fullScreen={true}
      >
        <VTSLightboxDetail
          publication={props.publication}
          publicationTags={props.publicationTags}
          open={isLightboxOpen}
          onClose={handleCloseLightBox}
        />
      </Dialog>
    </>
  );
};

export default VTSPublication;
