import React from "react";

export enum childrenType {
  Publication = "publication",
  Advertisement = "advertisement",
}
export interface VTSCardWrapperProps {
  children: React.ReactNode;
  publicationTags?: boolean;
  childrenType: childrenType;
  positionInList: number;
}
