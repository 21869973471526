/* eslint-disable react/jsx-newline */
import {
  Button,
  Card,
  CardContent,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import LinkIcon from "@material-ui/icons/Link";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { isBrowserEnvironment } from "../../../helpers/clientSide/isBrowserEnvironment";
import { mapWpImageInformation } from "../../../helpers/clientSide/WordPress/mapWpImageInformation";
import { SanitizeHTML } from "../SanitizeHTML/SanitzeHTML";
import WordPressImage from "../WordPressImage/WordPressImage";
import { VTSLightboxDetailProps } from "./VTSLightboxDetailInterfaces";
import { useVTSLightboxDetailStyles } from "./VTSLightboxDetailStyles";

const VTSLightboxDetail = (props: VTSLightboxDetailProps): JSX.Element => {
  const classes = useVTSLightboxDetailStyles();
  const { t } = useTranslation();

  const pdfThumbnailUrl = props.publication.paperFields?.publication?.sourceUrl;
  const pdfUrl = props.publication.paperFields?.publication?.mediaItemUrl;
  const keyFacts = props.publication.paperFields?.keyfacts;
  const externalLink = props.publication.paperFields?.externalLink;
  const description = props.publication.paperFields?.description;

  const customPreviewPage = mapWpImageInformation(
    props.publication.paperFields?.previewPage
  );

  const downloadPublication = () => {
    if (isBrowserEnvironment()) {
      window.open(pdfUrl);
    }
  };

  const openExternalLink = () => {
    if (isBrowserEnvironment()) {
      window.open(externalLink);
    }
  };

  return (
    <>
      <Button
        className={clsx(classes.button, classes.closeButton)}
        onClick={props.onClose}
      >
        <CloseIcon />
      </Button>

      <DialogContent className={classes.dialogContent}>
        <Grid
          item={true}
          container={true}
          md={5}
          className={classes.dialogContentImage}
        >
          {!pdfThumbnailUrl && customPreviewPage && (
            <Grid item={true} container={true} className={classes.wrapperImage}>
              <WordPressImage
                imageInformation={customPreviewPage}
                imageClass={classes.image}
              />
            </Grid>
          )}

          {pdfThumbnailUrl && (
            <Grid item={true} container={true} className={classes.wrapperImage}>
              <img
                src={pdfThumbnailUrl}
                alt={props.publication.title}
                className={classes.image}
              />
            </Grid>
          )}
        </Grid>

        <Grid
          item={true}
          container={true}
          md={7}
          className={classes.dialogContentDetails}
        >
          <Grid item={true} container={true}>
            <Grid
              item={true}
              container={true}
              className={classes.wrapperPmidDoi}
            >
              {props.publication.paperFields?.pmid && (
                <Grid item={true}>
                  <Typography
                    variant="body2"
                    component="h1"
                    className={classes.pmid}
                  >
                    {t("publications.pmid")}:{" "}
                    {props.publication.paperFields.pmid}
                  </Typography>
                </Grid>
              )}

              {props.publication.paperFields?.doi && (
                <Grid item={true}>
                  <Typography
                    variant="body2"
                    component="h1"
                    className={classes.doi}
                  >
                    {t("publications.doi")}: {props.publication.paperFields.doi}
                  </Typography>
                </Grid>
              )}
            </Grid>

            <Grid item={true} container={true}>
              <Typography variant="h2" component="h1">
                <strong>
                  <SanitizeHTML html={props.publication?.title || ""} />
                </strong>
              </Typography>
            </Grid>

            {props.publicationTags && props.publicationTags.length !== 0 && (
              <Grid
                item={true}
                container={true}
                className={classes.wrapperPublicationTags}
              >
                {props.publicationTags.map((publicationTag) => {
                  return (
                    <Grid key={publicationTag} item={true}>
                      <Card className={classes.publicationTag}>
                        <CardContent>{publicationTag}</CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            )}

            <Grid item={true} container={true}>
              <Typography variant="body2" component="div">
                <SanitizeHTML html={description || ""} />
              </Typography>
            </Grid>
          </Grid>

          {[keyFacts, pdfUrl, externalLink].some(
            (element) => element !== undefined
          ) && (
            <Grid item={true} container={true} className={classes.moreDetails}>
              <Grid item={true} container={true} className={classes.keyFacts}>
                {keyFacts &&
                  keyFacts.map((keyFact) => {
                    return (
                      <Grid
                        key={keyFact?.factTitle}
                        className={classes.keyFact}
                      >
                        <Typography
                          variant="h2"
                          component="p"
                          className={classes.factNumber}
                        >
                          {keyFact?.factNumber}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="h2"
                          className={classes.factTitle}
                        >
                          <SanitizeHTML html={keyFact?.factTitle || ""} />
                        </Typography>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      {[pdfUrl, externalLink].some((element) => element !== undefined) && (
        <Grid container={true} className={classes.wrapperDownloadLinkButtons}>
          {pdfUrl && (
            <Button className={classes.button} onClick={downloadPublication}>
              <VerticalAlignBottomIcon fontSize="medium" />
            </Button>
          )}

          {externalLink && (
            <Button className={classes.button} onClick={openExternalLink}>
              <LinkIcon fontSize="medium" />
            </Button>
          )}
        </Grid>
      )}
    </>
  );
};

export default VTSLightboxDetail;
