import "@fontsource/dm-mono/400.css";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { animated } from "react-spring";
import { VitisLogoWithoutText } from "../../../__generated__/svgComponents/VitisLogoWithoutText";
import { VTSCircularLogoTextProps } from "./VTSCircularLogoTextInterfaces";
import { useVTSCircularLogoTextViewModel } from "./VTSCircularLogoTextViewModel";

const VTSCircularLogoText = (props: VTSCircularLogoTextProps): JSX.Element => {
  const { classes, individualLetters, styles } =
    useVTSCircularLogoTextViewModel(props);

  return (
    <Box className={clsx(classes.wrapper, props.wrapperClass)}>
      <VitisLogoWithoutText className={classes.logo} />

      <div className={classes.text}>
        <animated.div className={classes.scrollRotation} style={styles}>
          {individualLetters}
        </animated.div>
      </div>
    </Box>
  );
};

export default VTSCircularLogoText;
