import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useVTSAutocompleteStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: "100%",
      "&.MuiAutocomplete-endAdornment": {
        "&.MuiAutocomplete-clearIndicator": {
          marginRight: theme.spacing(0.5),
        },
      },
      "& .MuiChip-root": {
        backgroundColor: `${theme.palette.grey[100]} !important`,
      },
      "& .MuiChip-label": {
        color: theme.palette.secondary.main,
      },
      "& .MuiChip-deleteIcon": {
        color: theme.palette.secondary.main,
      },
      "& .Mui-focused": {
        color: theme.palette.secondary.main,
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.secondary.main,
        },
      },
    },
    checkbox: {
      marginRight: theme.spacing(1),
    },
  })
);
