import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useVTSCardAdvertisementStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonBase: {
      width: "100%",
      height: "100%",
    },
    cardContent: {
      display: "flex",
      alignItems: "center",
      height: "100%",
      width: "100%",
      padding: theme.spacing(1.2),
    },
    textWrapper: {
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      columnGap: theme.spacing(2),
    },
    handWrittenSlogan: {
      textAlign: "left",
    },
    arrowIcon: {
      color: theme.palette.primary.contrastText,
    },
  })
);
