import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import VTSServiceCard from "../../01-atoms/VTSServiceCard/VTSServiceCard";
import { VTSHomepageServicesCardsProps } from "./VTSHomepageServicesCardsInterfaces";
import { VTSHomepageServicesCardsStyles } from "./VTSHomepageServicesCardsStyles";

const VTSHomepageServicesCards = (
  props: VTSHomepageServicesCardsProps
): JSX.Element => {
  const classes = VTSHomepageServicesCardsStyles();
  const theme = useTheme();
  const isBigViewport = useMediaQuery(theme.breakpoints.up("md"));

  return isBigViewport ? (
    <Grid container={true} className={classes.wrapperBigViewport} spacing={2}>
      <Grid
        item={true}
        container={true}
        className={classes.leftColumnWrapper}
        md={6}
        spacing={2}
      >
        {props.services?.map((service, index) => {
          return index % 2 === 0 ? (
            <VTSServiceCard key={service.id} service={service} />
          ) : undefined;
        })}
      </Grid>

      <Grid
        item={true}
        container={true}
        className={classes.rightColumnWrapper}
        md={6}
        spacing={2}
      >
        {props.services?.map((service, index) => {
          return index % 2 !== 0 ? (
            <VTSServiceCard key={service.id} service={service} />
          ) : undefined;
        })}
      </Grid>
    </Grid>
  ) : (
    <Grid container={true} className={classes.wrapperSmallViewport} spacing={2}>
      {props.services?.map((service) => {
        return <VTSServiceCard key={service.id} service={service} />;
      })}
    </Grid>
  );
};

export default VTSHomepageServicesCards;
