import { ButtonBase, CardContent, Grid, Typography } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import { navigate } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";
import { Template } from "../../../../config/wordPressConfig";
import { isBrowserEnvironment } from "../../../helpers/clientSide/isBrowserEnvironment";
import { usePathForTemplate } from "../../../queries/wpPage/usePathForTemplate";
import { useWpVitisSettings } from "../../../queries/wpVitisSettings/useWpVitisSettings";
import { SanitizeHTML } from "../SanitizeHTML/SanitzeHTML";
import { useVTSCardAdvertisementStyles } from "./VTSCardAdvertisementStyles";

const VTSCardAdvertisement = (): JSX.Element => {
  const classes = useVTSCardAdvertisementStyles();
  const { t } = useTranslation();
  const contactPagePath = usePathForTemplate(Template.Contact);
  const infoData = useWpVitisSettings()?.vitisFields;
  const handWrittenSlogan = infoData?.handwrittenSlogan;

  const handleClickOpen = () => {
    if (isBrowserEnvironment()) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(contactPagePath);
    }
  };

  return (
    <ButtonBase onClick={handleClickOpen} className={classes.buttonBase}>
      <CardContent className={classes.cardContent}>
        <Grid container={true} className={classes.textWrapper}>
          <Typography variant="body1" color="textSecondary">
            <strong>{t("advertisement.title")}</strong>
          </Typography>

          <Typography variant="subtitle2" className={classes.handWrittenSlogan}>
            <SanitizeHTML html={handWrittenSlogan || ""} />
          </Typography>
        </Grid>

        <ArrowForwardIos className={classes.arrowIcon} />
      </CardContent>
    </ButtonBase>
  );
};

export default VTSCardAdvertisement;
