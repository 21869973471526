import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useVTSHomepagePublicationsSectionStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      wrapper: {
        flexDirection: "row",
        justifyContent: "space-between",
      },
      details: {
        flexDirection: "column",
      },
      title: {
        fontWeight: theme.typography.fontWeightBold,
        paddingBottom: theme.spacing(1),
        "& > p": {
          margin: 0,
        },
      },
      button: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
          width: "fit-content",
        },
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: ".5em",
        paddingLeft: theme.spacing(1.5),
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1.5),
        paddingBottom: theme.spacing(1),
        marginTop: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(3),
        },
      },
      buttonText: {
        color: theme.palette.secondary.light,
      },
      buttonArrowWrapper: {
        textAlign: "center",
        justifyContent: "flex-end",
        alignItems: "center",
      },
      buttonArrow: {
        color: theme.palette.secondary.contrastText,
        [theme.breakpoints.up("md")]: {
          marginLeft: theme.spacing(1),
        },
      },
    })
);
