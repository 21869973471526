import { useMemo } from "react";
import { VTSAutocompleteProps } from "../../../components/01-atoms/VTSAutocomplete/VTSAutocompleteInterfaces";
import {
  PublicationPropertyName,
  PublicationsAutocompleteData,
  VTSAutocompleteFilter,
} from "../../../interfaces/publication/morePublicationTypes";
import { WpPublication } from "../../../queries/wpPaper/useAllPaper";
import { getPublicationYear } from "../publicationHelper/publication";
import { useAutocompleteFilter } from "./useAutocompleteFilter";

const vtsAutocompletes: Record<PublicationPropertyName, VTSAutocompleteFilter> =
  Object.freeze({
    [PublicationPropertyName.Year]: {
      label: "Jahr",
      titleGetter: getPublicationYear,
    },
  });

export const useVTSPublicationsAutocomplete = (
  publications: WpPublication[],
  property: keyof typeof vtsAutocompletes
): PublicationsAutocompleteData => {
  const autocompleteData = vtsAutocompletes[property];

  const { onChange, filterCallback, options, reset, value } =
    useAutocompleteFilter({
      data: publications,
      getValue: autocompleteData.titleGetter,
    });

  const props: VTSAutocompleteProps = useMemo(
    () => ({
      onChange,
      label: autocompleteData.label,
      options,
      value,
    }),
    [autocompleteData.label, onChange, options, value]
  );

  return [props, filterCallback, reset];
};
