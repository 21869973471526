import { createStyles, makeStyles, Theme } from "@material-ui/core";

const imageSizeForViewports: Record<string, number> = {
  mdUp: 500,
  sm: 370,
  xs: 140,
};

const shapeTop: Record<string, number> = {
  mdUp: 150,
  sm: (150 / imageSizeForViewports.mdUp) * imageSizeForViewports.sm,
  xs: (150 / imageSizeForViewports.mdUp) * imageSizeForViewports.xs,
};

const shapeBottom: Record<string, number> = {
  mdUp: 25,
  sm: (25 / imageSizeForViewports.mdUp) * imageSizeForViewports.sm,
  xs: (25 / imageSizeForViewports.mdUp) * imageSizeForViewports.xs,
};

const shapeSides: Record<string, number> = {
  mdUp: 71,
  sm: (71 / imageSizeForViewports.mdUp) * imageSizeForViewports.sm,
  xs: (71 / imageSizeForViewports.mdUp) * imageSizeForViewports.xs,
};

const imageSides: Record<string, number> = {
  mdUp: 100,
  sm: (100 / imageSizeForViewports.mdUp) * imageSizeForViewports.sm,
  xs: (100 / imageSizeForViewports.mdUp) * imageSizeForViewports.xs,
};

export const useVTSHomePageHeroStyles = makeStyles((theme: Theme) => {
  const imageRotation = 45;
  return createStyles({
    wrapper: {
      overflow: "hidden",
    },
    hero: {
      borderRadius: theme.spacing(1.5),
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        justifyContent: "center",
        paddingTop: theme.spacing(2),
      },
    },
    textContainer: {
      width: "100%",
      flex: "0.97 1 50%",
      display: "flex",
      flexDirection: "column",
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(0, 2),
      },
    },
    smallTitle: {
      color: theme.palette.secondary.dark,
      letterSpacing: 1.85,
      marginBottom: theme.spacing(0.5),
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      margin: 0,
      "& > p": {
        margin: 0,
      },
    },
    handWrittenSlogan: {
      color: theme.palette.primary.contrastText,
      textAlign: "left",
      marginTop: theme.spacing(-1.5),
      marginLeft: theme.spacing(12),
      marginBottom: 0,
      [theme.breakpoints.only("sm")]: {
        marginLeft: theme.spacing(10),
      },
      [theme.breakpoints.only("xs")]: {
        marginTop: theme.spacing(-1),
        marginLeft: 0,
      },
    },
    imageShapeContainer: {
      position: "relative",
      display: "flex",
      minWidth: imageSizeForViewports.mdUp,
      height: imageSizeForViewports.mdUp,
      [theme.breakpoints.down("sm")]: {
        minWidth: imageSizeForViewports.sm,
        height: imageSizeForViewports.sm,
        marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: imageSizeForViewports.xs,
        height: imageSizeForViewports.xs,
      },
    },
    shapeContainer: {
      display: "flex",
      alignItems: "flex-end",
      position: "absolute",
      mixBlendMode: "multiply",
      opacity: 0.65,
      zIndex: 1,
      top: shapeTop.mdUp,
      right: shapeSides.mdUp,
      bottom: shapeBottom.mdUp,
      left: -shapeSides.mdUp,
      [theme.breakpoints.down("sm")]: {
        top: shapeTop.sm,
        right: shapeSides.sm,
        bottom: shapeBottom.sm,
        left: -shapeSides.sm,
      },
      [theme.breakpoints.down("xs")]: {
        top: shapeTop.xs,
        right: shapeSides.xs,
        bottom: shapeBottom.xs,
        left: -shapeSides.xs,
      },
    },
    shape: {
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.secondary.main,
      clipPath: "inset(0% 33% 0% 33% round 25px 90px 90px 90px)",
      "-webkit-clip-path": "inset(0% 33% 0% 33% round 25px 90px 90px 90px)",
      transform: `rotate( -${imageRotation}deg )`,
      [theme.breakpoints.down("xs")]: {
        clipPath: "inset(0% 33.01% 0% 33% round 25px 90px 90px 90px)",
        "-webkit-clip-path":
          "inset(0% 33.01% 0% 33% round 25px 90px 90px 90px)",
      },
    },
    imageContainer: {
      flex: "1 0 auto",
      display: "flex",
      position: "absolute",
      top: 0,
      right: -imageSides.mdUp,
      bottom: 0,
      left: imageSides.mdUp,
      overflow: "hidden",
      userSelect: "none",
      [theme.breakpoints.down("sm")]: {
        right: -imageSides.sm,
        left: imageSides.sm,
      },
      [theme.breakpoints.down("xs")]: {
        right: -imageSides.xs,
        left: imageSides.xs,
      },
    },
    imageInnerContainer: {
      flex: "1 1 auto",
      clipPath: "inset(0% 33% 0% 33% round 90px)",
      "-webkit-clip-path": "inset(0% 33% 0% 33% round 90px)",
      transform: `rotate( ${imageRotation}deg )`,
      [theme.breakpoints.down("xs")]: {
        clipPath: "inset(0% 32.99% 0% 33% round 90px)",
        "-webkit-clip-path": "inset(0% 32.99% 0% 33% round 90px)",
      },
    },
    image: {
      width: "100%",
      height: "100%",
      transform: `rotate( -${imageRotation}deg )`,
    },
  });
});
