import { Card, Grid } from "@material-ui/core";
import { rethrow } from "@nvon/baseline";
import clsx from "clsx";
import { motion, Spring, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import useInView from "react-cool-inview";
import { childrenType, VTSCardWrapperProps } from "./VTSCardWrapperInterfaces";
import { useVTSCardWrapperStyles } from "./VTSCardWrapperStyles";

const spring = (positionInList: number): Spring => ({
  type: "spring",
  delay: positionInList * 0.1,
  mass: 0.5,
  damping: 18,
});

const VTSCardWrapper = (props: VTSCardWrapperProps): JSX.Element => {
  const classes = useVTSCardWrapperStyles();

  const animation = useAnimation();
  const { observe, inView } = useInView();

  useEffect(() => {
    if (inView) {
      animation.start("visible").catch(rethrow);
    }
  }, [animation, inView]);

  return (
    <Grid item={true} container={true} className={classes.cardWrapper}>
      <motion.div
        className={classes.cardMotion}
        ref={observe}
        initial="hidden"
        animate={animation}
        variants={{
          hidden: { opacity: 0.1 },
          visible: { opacity: 1 },
        }}
        transition={spring(props.positionInList)}
        layout={true}
      >
        <Card
          className={clsx(
            classes.card,
            props.publicationTags === true ? classes.cardMinHeight : "",
            props.childrenType === childrenType.Publication
              ? classes.publication
              : classes.advertisement
          )}
        >
          {props.children}
        </Card>
      </motion.div>
    </Grid>
  );
};

export default VTSCardWrapper;
