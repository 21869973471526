import { Button, Grid, Typography } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import { navigate } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";
import { Template } from "../../../../config/wordPressConfig";
import { isBrowserEnvironment } from "../../../helpers/clientSide/isBrowserEnvironment";
import { usePathForTemplate } from "../../../queries/wpPage/usePathForTemplate";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import { SanitizeHTML } from "../../01-atoms/SanitizeHTML/SanitzeHTML";
import VTSPublicationList from "../../02-molecules/VTSPublicationList/VTSPublicationList";
import VTSUnderlinedTitle from "../../02-molecules/VTSUnderlinedTitle/VTSUnderlinedTitle";
import { VTSHomepagePublicationsSectionProps } from "./VTSHomepagePublicationsSectionInterfaces";
import { useVTSHomepagePublicationsSectionStyles } from "./VTSHomepagePublicationsSectionStyles";

const VTSHomepagePublicationsSection = (
  props: VTSHomepagePublicationsSectionProps
): JSX.Element => {
  const classes = useVTSHomepagePublicationsSectionStyles();
  const pageData = useSinglePage(props.pageId);
  const publicationsData = pageData?.pageFields?.home?.publicationsTeaser;
  const { t } = useTranslation();

  const titleRaw = publicationsData?.title;

  const underlinedTitle = titleRaw && (
    <VTSUnderlinedTitle
      titleRaw={titleRaw}
      typographyProps={{
        variant: "h2",
        className: classes.title,
      }}
    />
  );

  const publicationPagePath = usePathForTemplate(Template.Publications);

  const handleClickButton = () => {
    if (isBrowserEnvironment()) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(publicationPagePath);
    }
  };

  return (
    <Grid container={true} className={classes.wrapper}>
      <Grid item={true} md={4} container={true} className={classes.details}>
        {underlinedTitle}

        <Typography variant="body1" component="div">
          <SanitizeHTML html={publicationsData?.description || ""} />
        </Typography>

        <Button
          onClick={handleClickButton}
          className={classes.button}
          variant="contained"
          color="secondary"
        >
          <Grid item={true} xs={11} className={classes.buttonText}>
            {t("publications.morePapers")}
          </Grid>

          <Grid
            item={true}
            xs={1}
            container={true}
            className={classes.buttonArrowWrapper}
          >
            <ArrowForwardIos className={classes.buttonArrow} />
          </Grid>
        </Button>
      </Grid>

      <Grid item={true} xs={12} md={7}>
        <VTSPublicationList numberOfPublications={props.numberOfPublications} />
      </Grid>
    </Grid>
  );
};

export default VTSHomepagePublicationsSection;
