import { createStyles, makeStyles } from "@material-ui/core";

export const useVTSPublicationListStyles = makeStyles(() =>
  createStyles({
    wrapperPublicationsList: {
      flexDirection: "column",
    },
    wrapperButtonLoadMore: {
      justifyContent: "center",
    },
  })
);
