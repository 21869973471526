import { Grid } from "@material-ui/core";
import { useLocation } from "@reach/router";
import React from "react";
import { Template } from "../../../../config/wordPressConfig";
import { usePageUrl } from "../../../helpers/clientSide/hooks/usePageLink";
import { usePageForTemplate } from "../../../queries/wpPage/usePageForTemplate";
import { height } from "../../02-molecules/VTSHomepageServicesCards/VTSHomepageServicesCardsStyles";
import VTSCardWithBackground from "../VTSCardWithBackground/VTSCardWithBackground";
import { InfoPosition } from "../VTSCardWithBackground/VTSCardWithBackgroundInterfaces";
import { VTSServiceCardProps } from "./VTSServiceCardInterfaces";
import { useVTSServiceCardStyles } from "./VTSServiceCardStyles";

const VTSServiceCard = (props: VTSServiceCardProps): JSX.Element => {
  const classes = useVTSServiceCardStyles();
  const { service } = props;

  const location = useLocation();
  const slug = usePageForTemplate(Template.Services)?.slug;
  const urlElements: string[] =
    slug === "" || slug === undefined
      ? [location.origin]
      : [location.origin, slug];
  const standardlinkTarget = usePageUrl(urlElements, service.slug);

  const linkTarget = service.linkTarget || standardlinkTarget;

  return (
    <Grid item={true} className={classes.card} key={service.id}>
      <VTSCardWithBackground
        key={service.title}
        title={service.title}
        infoPosition={InfoPosition.Center}
        arrowRotated={true}
        showContactInfo={false}
        height={`${height}rem`}
        backgroundImage={service.detailImage}
        linkTarget={linkTarget}
      />
    </Grid>
  );
};

export default VTSServiceCard;
