import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const VTSHomepageServicesInfoStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
    },
    bulletPoints: {
      marginTop: theme.spacing(2.5), // 40px
      display: "flex",
      flexDirection: "column",
      "& > :not(:last-child)": {
        marginBottom: theme.spacing(1),
      },
      "&:last-child": {
        marginBottom: 0,
      },
    },
    bulletPoint: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    bulletPointText: {
      paddingLeft: theme.spacing(1),
      [theme.breakpoints.up("lg")]: {
        paddingLeft: 0,
      },
    },
    wrapperIcon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: theme.spacing(2.25), // 36px
      height: theme.spacing(2.25), // 36px
      backgroundColor: theme.palette.primary.main,
      borderRadius: "10%",
    },
    icon: {
      color: theme.palette.secondary.dark,
    },
  })
);
